.compare-btn{
    position: fixed;
    bottom: 0;
    right: 45%;
    background: #ddd;
    display: flex;
    align-items: center;
    padding: 6px 18px;
    border: 3px solid #00A09D;
    border-radius: 20px 20px 0 0;
    gap:8px;
    cursor: pointer;
    font-weight: 400;
}

.compare-count{
    background: #00A09D;
    color: beige;
    padding: 2px 5px;
}


.product-compare-table table{
    margin: 30px;
    border-collapse: collapse;
}

.product-compare-table caption{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding: 10px 0;
}

.product-compare-table th{
    position: relative;
    min-height: 500px;
}

.product-compare-table td{
    text-align: center;
}

.product-compare-table tr,.product-compare-table th,.product-compare-table td{
    border:1px solid #8080806e;
}

.product-compare-table .delete-icon{
    color: beige;
    background: #00A09D;
}