.compare-btn{
    position: fixed;
    bottom: 0;
    right: 45%;
    background: #ddd;
    display: flex;
    align-items: center;
    padding: 6px 18px;
    border: 3px solid #00A09D;
    border-radius: 20px 20px 0 0;
    gap:8px;
    cursor: pointer;
    font-weight: 400;
}

.compare-count{
    background: #00A09D;
    color: beige;
    padding: 2px 5px;
}


.company-compare-table table{
    margin: 30px;
    border-collapse: collapse;
}

.company-compare-table caption{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding: 10px 0;
}

.company-compare-table th{
    position: relative;
    min-height: 500px;
}

.company-compare-table td{
    text-align: center;
}

.company-compare-table tr,.company-compare-table th,.company-compare-table td{
    border:1px solid #8080806e;
}

.company-compare-table .delete-icon{
    color: beige;
    background: #00A09D;
}


.compare-table{
    width: 90%;
    border-collapse: collapse;
}



.compare-table tr{
    cursor: pointer;
}

.compare-table th{
    font-weight: 700;
}

.compare-table td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding:8px 4px;
  }
  
.compare-table tr:nth-child(even) {
    background-color: #dddddd;
}

.compare-table thead{
    position: sticky;
    /* top: 50px; */
    background: #0084C8;
}

.compare-table thead tr{
    /* position: sticky; */
    /* top: 50px; */
    background: #0084C8 !important;
}
