
@media (max-width:828){

}


@media screen and (max-width: 875px) {
    .header-left .locate-user .location {
        display: none;
    }

    .header-left .locate-user{
        justify-content: start;
        min-width: 30px;
    }
}


@media screen and (max-width: 370px) {

}