.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 5%;
  font-size: 100px;
  background: rgb(14, 13, 13);
  color: beige;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  border: none;
  box-shadow: 0 5px 10px #ccc;
  z-index: 1;
}

.back-to-top:hover {
  background: red;
}