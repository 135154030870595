/* .div_border{
    border: 1px solid black;
    margin-top: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
} */
/* p,
label {
    font: 10px 'Fira Sans', sans-serif;
} */

/* input {
    margin: .4rem;
} */
.float-container {
    border: 3px solid rgb(17, 51, 124);
    padding: 20px;
}

.float-child-left {
    float: left;
    font-weight: bolder;
     /* width: 30%; */
    /* border: 2px solid red; */
}  
.float-child-right {
    width: 40%;
    margin: 0 auto;
    padding: 20px;
    font-weight: bolder;
    
}
.bg {
    background-color: rgb(230, 223, 223);
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}

