.cart-summary{
    min-width: 25%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    border: 1px solid #8080806e;
    padding: 20px 5px;
    font-size: 13px;
    font-weight: 400;
}

.cart-summary .order-line{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 25%;
}

.cart-summary .cart-summary-products{
    max-height: 50vh;
    overflow: auto;
}

.cart-summary .order-line span{
    word-wrap: break-word;
    font-weight: 400;
    max-width: 25%;
}

.cart-summary img{
    width: 60px;
    height: 60px;
}

.cart-summary .heading{
    font-weight: bold;
    border-bottom: 1px solid #8080806e;
    padding:10px 0;
}

.cart-summary .order-total{
    border-top: 1px solid #8080806e;
    font-size: 16px;
    margin-top: 5px;
}

.cart-summary .subtotal{
    border-bottom: 1px solid #8080806e;
    padding:10px 0;
}

.cart-summary .total{
    font-weight: 600;
    padding:10px 0;
}

.details{
    display: flex;
    padding: 5px 0;
}

.details .left1{
    flex-basis: 65%;
    text-align: right;
}

.details .right1{
    flex-basis: 35%;
    text-align: right;
}

.promocode-link{
    padding: 15px 0;
    text-align: right;
    font-weight: 100;
    color: #00A09D;
}

.promocode-link:hover{
    cursor: pointer;
    text-decoration: underline;
}