.sliders{
    width: 100%;
    height: 300px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.sliders-title{
    font-weight: bold;
    padding-left: 15px;
}

.sliders.top{
    background-color: #000000;
    color: beige;
    font-weight: 700;
    position: relative;
    margin-top: 0;
    /* Piyush Kumar 2/6/2023 for slider details  height incresed */
    height: 325px;
    /* Piyush Kumar End */
}

.sliders__item{
    margin:15px;
}

.sliders__itemDetails{
    margin:2px;
}

.sliders__item img{
    width: 250px;
    height: 250px;
}

.small-img{
    width: 250px;
    height: 200px;
}

.product-sliders {
    width: 60%;
}
