.console .modules{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
    gap: 20px;
    flex-wrap: wrap;
}

.console .select_company{
    margin: 5px 20px;
    font-weight: bold;
    float: right;
}

  /* @media screen and (max-width: 600px) {
    .console-form {
      padding: 0;
    }
  } */
.console .console__module{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 140px;
    word-wrap: break-word;
    font-size: 18px;
    font-weight: 700;
    background-color: #D5D3D6;
    border: 1px solid #8080806e;
    border-radius: 10%;
    cursor: pointer;
}
@media screen and (max-width: 600px) {
    .console .console__module{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        height: 40px;
        word-wrap: break-word;
        font-size: 14px;
        font-weight: 700;
        background-color: #D5D3D6;
        border: 1px solid #8080806e;
        border-radius: 10%;
        cursor: pointer;
    }
  }

.console-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 70px;
    font-size: 10px;
}
@media screen and (max-width: 600px) {
    .console-form {
      padding: 0;
    }
  }

.console-form h4{
    font-size: 16px;
}

.console-form header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #8080806e;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.console-form header .header__left{
    /* display: flex; */
    display: contents;
    gap: 5px;
    align-items: center;
}

.console-form header .header__left button{
    max-height: 30px;
    font-size: 13px;
    /* mohit added margin right in header button  */
    margin-right:5px;
}

.console-form__heading{
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    margin-right: 30px;
}

.console-form form{
    width: 100%;
}

.console-form form fieldset > span{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.console-form__inputs{
    width: 30%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    font-size: 10px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.console-form__inputs.checkbox{
    flex-direction: row;
}

.console-form__inputs label{
    font-weight: 600;
    padding-bottom: 5px;
    color:#212529;
    width: 30%;
    word-wrap: break-word;
}

.console-form__inputs input{
    padding: 5px;
    border: 1px solid #727679;;
    border-radius: 0px;
    margin-bottom: 10px;
}

.console-form__inputs .prefix{
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #999;
    padding: 10px 5px;
}

.console-table-line{
    width: 100%;
    border-collapse: collapse;
    font-size: 11px;
}

.console-table-line thead{
    position: sticky;
    top: 50px;
    background: #0084C8;
}

.console-table-line tr{
    cursor: pointer;
}

.console-table-line th{
    font-weight: 700;
}

.console-table-line td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 2px;
  }
  
.console-table-line tr:nth-child(even) {
    background-color: #dddddd;
}

.error{
    margin-left: 5px;
    font-size: 13px;
    color: red;
  }
