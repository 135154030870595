
.table-style{
    width: 100%;
    border-collapse: collapse;
}

.table-style thead{
    position: sticky;
    /* top: 50px; */
    background: #0084C8;
}

.table-style tr{
    cursor: pointer;
}

.table-style th{
    font-weight: 700;
}

.table-style td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
.table-style tr:nth-child(even) {
    background-color: #dddddd;
}



@media screen and (max-width: 600px) {
    table.table-style {
        table-layout: fixed;
        width: 100%;
        height: 400px;
        
    }
    table.table-style th,td {
        border-style: solid;
        border-width: 5px;
        border-color: #BCBCBC;
        word-wrap: break-word;
    }
    table.table-style  input.table-input{
        width: 50px;

    }
    header.inventory-header{
        display: flex;
        flex-wrap: wrap;
    }
    .console-form header {
        width: 90%;
      }
}

@media screen and (max-width: 425px) {
    table.table-style  input.table-input{
        width: 40px;

    }
}
