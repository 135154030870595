.delivery-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 70px;
    font-size: 10px;
}

.delivery-form header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #8080806e;
    padding: 0 10px;
    margin-bottom: 5px;
}

.delivery-form header .header__left{
    display: flex;
    gap: 10px;
    align-items: center;
}

.delivery-form header .header__left button{
    max-height: 30px;
    font-size: 13px;
}

.delivery-form__heading{
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    margin-right: 30px;
}

.delivery-form form{
    width: 100%;
}

.delivery-form form fieldset > span{
    display: flex;
    gap: 20px;
}

.delivery-form__inputs{
    width: 33%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    font-size: 10px;
    align-items: center;
    justify-content: flex-start;
}

.delivery-form__inputs label{
    font-weight: 600;
    padding-bottom: 5px;
    color:#212529;
    width: 30%;
    word-wrap: break-word;
}

.delivery-form__inputs input{
    padding: 5px;
    border: 1px solid #727679;;
    border-radius: 0px;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.delivery-form__inputs .prefix{
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #999;
    padding: 10px 5px;
}
