.company-name{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    width: 100%;
}

.cart-data{
    margin-top: 20px;
    width: 100%;
    display: flex;
}

.cart-table{
    /* width: 70%; */
    
    display: flex;
    justify-content: center;
}

.cart-table.disabled{
    pointer-events: none;
    opacity: 0.5;
}

.cart-table-empty{
    margin: 20px 40px;
    width: 55%;
    text-align: left;
    line-height: 45px;
    border: 1px solid #bee5eb;
    border-radius: 0.25rem;
    color: #0c5460;
    background-color: #d1ecf1;
    font-size: 0.875rem;
    font-weight: 400;
}

.cart-value{
    min-width: 25%;
    min-height: 150px;
    max-height: 45vh;
    display: flex;
    flex-direction: column;
    border: 1px solid #8080806e;
    padding: 20px;
     /* Ankit Start 13 April 2023 */
     overflow: auto;
     /* Ankit End 13 April 2023 */
}

.cart-value .heading{
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #8080806e;
    padding:10px 0;
}

.cart-value .subtotal{
    font-size: 13px;
    border-bottom: 1px solid #8080806e;
    padding:10px 0;
}

.cart-value .total{
    font-size: 13px;
    font-weight: 600;
    padding:10px 0;
}

.details{
    display: flex;
    padding: 5px 0;
}

.details.column{
     /* Ankit Start 12 April 2023 */
     flex-direction: column;
     /* Ankit End 12 April 2023 */
}

/* Ankit Start 13 April 2023 */
.applied_coupons{
    padding: 5px ;
}
/* Ankit End 13 April 2023 */

.details .left1{
    flex-basis: 65%;
    text-align: right;
}

.details .right1{
    flex-basis: 35%;
    text-align: right;
}

.promocode-link{
    font-size: 13px;
    padding: 15px 0;
    text-align: right;
    font-weight: 100;
    color: #00A09D;
}

.promocode-link:hover{
    cursor: pointer;
    text-decoration: underline;
}

.cart-table table{
    /* width: 90%; */
    border-top: 1px solid #8080806e;
    border-bottom: 1px solid #8080806e;
    font-size: 13px;
    border-collapse: collapse;
    height: 100px;
}

.cart-table tr:nth-child(even) {
    background-color: rgb(238, 234, 234);
  }

.cart-table table th {
    padding:5px;
    text-align: center;
    max-height: 70px !important;
  }

.cart-table table td{
    padding:5px;
    text-align: center;
    height: 70px !important;
}

.product{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-line .product .name{
    font-weight: 600;
    margin-left: 15px;
    color: #00A09D;
}

.order-line .product .image img{
    max-height: 64px;
    max-width: 64px;
    padding: 3px;
    margin-right: 10px;
    border-radius: 15%;
}

.order-line .qty input{
    width: 100px;
    height: 25px;
    border: 1px solid #8080806e;
    text-align: center;
}

.order-line .qty button{
    font-size: 20px;
    background: none;
    font-weight: bold;
    color: #00A09D;
    border: none;
    cursor: pointer;
}


.upload-image-holder{
    margin: 20px 40px;
    border: 1px solid #8080806e;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.input__container{
    padding: 10px;
}

.uploaded-image{
    display: inline-block;
    position: relative;
}

.uploaded-image .preview{
    width: 120px;
    height: 120px;
    object-fit: contain;
    padding: 10px 0;
    margin: 10px;
    border: 1px solid #8080806e;
}

.uploaded-image #close{
    position: absolute;
    border-radius: 50%;
    top: 2px;
    right: 3px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.comment-section{
    margin-top: 0;
    margin: 20px 40px;
    width: 55%;
    display: flex;
    flex-direction: column;
}

.comment-section label{
    font-weight: bold;
    padding-bottom: 5px;
}

.comment-section textarea{
    border: 1px solid #8080806e;
    word-wrap: break-word;
    min-height: 50px;
    text-align: start;
}

.delivery-section{
    margin: 20px 40px;
    width: 55%;
}

.delivery-section .top{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: bold;
}

.delivery-section .bottom{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.delivery-section .bottom button{
    cursor: pointer;
    font-size: 13px;
    color: beige;
    display: flex;
    align-items: center;
}

.bottom .btn1{
    background-color: #875A7B;
    border-color: #875A7B;
}

.bottom .btn2{
    background-color: #00A09D;
    border-color: #00A09D;
}

.camera-section{
    margin: 20px 40px;
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.camera-section .webCam{
    max-width: 280px;
    max-height: 210px;
}

.camera-section button{
    padding: 2px 5px;
    border-radius: 0;
    margin-right: 5px;
    background-color: #875a7b;
    color:beige;
    font-weight: 400;
    cursor: pointer;
    border: none;
}

.image {
    width: 100px;
  }
