.user-form__inputs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.user-form__inputs.checkbox{
    display: flex;
    align-items: center;
}

.user-form__inputs >span{
    width: 50%;
    padding-bottom: 10px;
}

.user-form__inputs label{
    font-weight: 600;
    padding-bottom: 5px;
    color:#212529;
    word-wrap: break-word;
    width: 40%;
}

.user-form__inputs input{
    padding: 5px;
    border: 1px solid #727679;;
    border-radius: 0px;
    margin-bottom: 10px;
}

.user-form__inputs .prefix{
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #999;
    padding: 10px 5px;
}
