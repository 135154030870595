.delivery-line{
    width: 100%;
    border-collapse: collapse;
    font-size: 11px;
}

.delivery-line thead{
    position: sticky;
    top: 50px;
    background: #0084C8;
}

.delivery-line tr{
    cursor: pointer;
}

.delivery-line th{
    font-weight: 700;
}

.delivery-line td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
.delivery-line tr:nth-child(even) {
    background-color: #dddddd;
}

/* Ankit Start bugid-1579 06-09-2022  */
.rating-star{
    color: rgb(255, 255, 139);
}
.weight_style{
    width: 1rem;
}

/* Ankit End bugid-1579 06-09-2022  */

