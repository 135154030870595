.header{
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: rgb(213, 211, 214,1);
    color:#212529;
    padding-left: 20px;
    padding-right: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.header-left{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.logo .big_logo{
    height: 40px;
    width: 200px;
    margin-right: 20px;
}

.header-left .locate-user{
    /* background-color: #0084c8; */
    color: #0084c8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 160px;
    max-width: 160px;
}

.header-left .locate-user .location{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #212529;
    font-weight: 700; 
}

.header-right .links button{
    background: transparent;
    border: none;
    cursor: pointer;
    margin-right: 15px;
}

.header-right .links .sign-in{
    font-weight: 600;
}

.search-bar{
    height: 100%;
    padding-left: 10px;
}


/* Ankit Start 26-dec-2022 */

.mobile-header {
    display: none;
}

.logo  .small_logo{
    display: none;
}

@media screen and (max-width: 1234px) {
    /* .logo img {
        display: none;
    } */

    .header-right .links{
        display: none;
    }

    .mobile-header .links button{
        background: transparent;
        border: none;
        cursor: pointer;
        margin-right: 15px;
        padding: 8px;
    }
    .mobile-header {
        display: flex;
    }
    .UserName{
        padding: 10px;
        font-size: 15px;
    }

    /* This will Override MenuItem mediaquery
    .css-svxxvo-MuiButtonBase-root-MuiIconButton-root {
        display: inline !important;
    }

    .css-9u9z3q-MuiButtonBase-root-MuiIconButton-root {
        display: inline !important;
    }
    .css-14jrsvo-MuiButtonBase-root-MuiIconButton-root {
        display: inline !important;
    } */
    .MenuIcon{
        display: inline !important;
    }
    .menu-IconBtn{
        display: inline !important;
    }
}

@media screen and (max-width: 600px) {
    .logo .big_logo {
        display: none;
    }
    .logo  .small_logo{
        display: inline;
    }
}

/* @media (min-width: 600px) {
    .css-svxxvo-MuiButtonBase-root-MuiIconButton-root {
        display: inline !important;
    }

    .css-9u9z3q-MuiButtonBase-root-MuiIconButton-root {
        display: inline !important;
    }
 } */


@media screen and (max-width: 370px) {
    .logo img {
        display: none;
        }
}

/* Ankit End 26-dec-2022 */
