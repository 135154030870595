.checkout-details{
    width: 100%;
    display: flex;
}

.checkout-details__left{
    flex-basis: 70%;
    padding: 15px;
}

.checkout-details__right{
    flex-basis: 30%;
    min-height: 300px;
    padding: 15px;
}

.navigation-btns{
    max-width: 100%;
    padding:10px 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigation-btns button{
    width: 100px;
    padding:2px;
    font-size: 13px;
    color: beige;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn__back{
    background-color: #875A7B;
    border-color: #875A7B;
}

.btn__next{
    background-color: #00A09D;
    border-color: #00A09D;
}

.btn__next.disabled{
    opacity: 0.5;
}