.products-pagination{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    gap: 8px;
}

.products-pagination > span{
    display: flex;
    align-items: center;
    gap: 10px;
    background:#d9cece;
    margin-right: 10px;
}