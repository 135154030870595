.address-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 70px;
}

@media screen and (max-width: 600px) {
    .address-form {
        padding: 0;               
    }
    
    
  }

.address-form__heading{
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #8080806e;
    padding-bottom: 10px;
    color:#212529;
}

.address-form form{
    width: 100%;
}

.address-form__inputs{
    width: 100%;
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    font-size: 13px;
}

.address-form__inputs label{
    font-weight: 600;
    padding-bottom: 5px;
    color:#212529;
}

.address-form__inputs input{
    min-width: 300px;
    padding: 7px;
    border: 1px solid #ced4da;
}

.address-form__inputs .prefix{
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #999;
    padding: 10px 5px;
}
