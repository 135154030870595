.dashboard{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px;
}

.dashboard button{
    max-height: 30px;
    font-size: 13px;
    margin-right: 5px;
}

.dashboard__filters{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.dashboard__linegraph{
    min-width: 40%;
    min-height: 40%;
    max-width: 60%;
    max-height: 60%;
}

.dashboard .otherInfo{
    display: flex;
    gap: 20px;
    padding: 10px;
    border-bottom: 1px solid #8080806e;
}

.dashboard .otherInfo table{
    border-collapse: collapse;
    font-size: 13px;
}

.dashboard .otherInfo table td,th{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 7px;
}

.dashboard .dashboard__orders{
    border-top: 1px solid #8080806e;
    padding: 10px;
}

.dashboard-table-line{
    margin-top: 5px;
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
}

.dashboard-table-line thead{
    position: sticky;
    top: 50px;
    background: #0084C8;
}

.dashboard-table-line tr{
    cursor: pointer;
}

.dashboard-table-line th{
    font-weight: 700;
}

.dashboard-table-line td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 2px;
  }
  
.dashboard-table-line tr:nth-child(even) {
    background-color: #dddddd;
}