.invoice-line{
    width: 100%;
    border-collapse: collapse;
    font-size: 11px;
}

.invoice-line thead{
    position: sticky;
    top: 50px;
    background: #0084C8;
}

.invoice-line tr{
    cursor: pointer;
}

.invoice-line th{
    font-weight: 700;
}

.invoice-line td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
.invoice-line tr:nth-child(even) {
    background-color: #dddddd;
}