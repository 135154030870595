.order-table{
    width: 100%;
    border-collapse: collapse;
}

.order-table thead{
    position: sticky;
    /* top: 50px; */
    background: #0084C8;
}

.order-table tr{
    cursor: pointer;
}

.order-table th{
    font-weight: 700;
}

.order-table td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
.order-table tr:nth-child(even) {
    background-color: #dddddd;
}
