.console-form__inputs label {
    width: auto;
    word-wrap: none;
    }

.loyalty_label{
    margin:10px
}

@media screen and (max-width: 500px) {
   .console-form__inputs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
   }

   .console-form__inputs label{
    width: 38%;
   }
   .console-form__inputs input{
    width: 40%;
    float: right;
    border-radius: 10px;
   outline: none;
   }
   .Btn-box{
    display: flex;
    justify-content: space-between;
    width: 90%;
   }
   .console-form-loyalty header {
    width: 100% !important; 
   }
}
