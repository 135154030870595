.help_support{
    padding: 0 70px;
    min-height: 400px;
}

.help_support__heading{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #8080806e;
    padding-bottom: 10px;    
    margin-top: 25px;
}

.help_support .details{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
}

.help_support .details >div{
    border:1px solid #8080806e;
    font-weight: 400;
    padding: 10px;
    word-wrap: break-word;
}