.payment-info{
    padding: 0 70px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    color:#212529;
    font-size: 0.875rem;
    font-weight: 400;
}
@media screen and (max-width: 600px) {
    .payment-info {
        /* padding-top: 50px;
        padding-right: 0;        
        padding-bottom: 50px;
        padding-left: 0; */
        padding: 0;
        min-height: 200px;
        
    }
    
    
  }


.payment-addresses {
    position: relative;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #8080806e;
}

.payment-addresses div{
    margin-bottom:7px;
}

.payment-addresses span{
    font-weight: 700;
}

.payment-info .edit-address{
    position: absolute;
    top:10px;
    right:10px;
    color: #00A09D;
    cursor: pointer;
}

.payment-methods, .payment-delivery-option{
    border: 1px solid #8080806e;
    margin-bottom: 20px;
}

.payment-methods .option, .payment-delivery-option .option{
    padding: 10px;
    color:#4d4d4d;
}

.payment-info .heading{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
}
