.user-options{
    color: #000000 !important;
    font-size: 13px !important;
    position: relative;
    display: inline-block;
}

.user-options div{
    position: absolute;
    background-color: #FFFFFF;
    min-width: max-content;
    right: 0px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 10px 10px;
}

.user-options .option{
    font-size: 13px;
    color: #000000 !important;
    padding-bottom: 5px;
    cursor: pointer;
    width: 90%;
    text-align: left;
}

.user-options .option:hover{
    background-color: #ddd;
}

.user-options .userName::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}



/* Ankit Start 26-dec-2022 */

@media screen and (max-width: 589px) {
    .user-options div{
        position: relative;
        background-color: #FFFFFF;
        min-width: max-content;
        right: 0px;
        overflow: auto;
        /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
        z-index: 1;
        /* padding: 10px 10px; */
    }
}


@media screen and (max-width: 370px) {
    
}

/* Ankit End 26-dec-2022 */