.companies-page {
    width: 90%;
    margin-left: 5%;
}

.companies-page .company-type1 {
    height: 45px;
    border-bottom: 1px solid #8080806e;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.companies-page .company-type1 .filter-by {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.companies-page .company-type1 .filter-by>* {
    font-size: 13px;
}

.companies-page .comp-type-subcategories {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.companies {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.company__card {
    flex-basis: 20%;
    word-wrap: break-word;
    margin: 15px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    padding: 3px;
    padding-bottom: 20px;
    transition: width 4s, height 4s;
    transition-timing-function: linear;
}

.company__card:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border: 1px solid rgba(0, 0, 0, 0.19);
}

.company-logo {
    position: relative;
}

.company-logo img {
    width: 230px;
    height: auto;
    object-fit: contain;
    cursor: pointer;
}

.company-logo .delivery-options {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #FFEB3B;
    padding: 2px 5px;
}

.company-details1 {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #8080806e;
}

.company-details3 {
    text-align: left;
    font-size: 16px;
    color: #f63030;
    margin-top: 4px;
}

.company-details1 .name {
    color: #066599;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.company-details1 .favorite-icon {
    font-size: 30px;
    cursor: pointer;
}

.company-details2 {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #8080806e;
}

.company-details2 .rating {
    background-color: #009E53;
    font-size: 11px;
    font-weight: 700;
    color: beige;
    padding: 4px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-not-available {
    margin: 20px 40px;
    width: 55%;
    font-size: 0.875rem;
    font-weight: 400;
}

.company-not-available div {
    text-align: left;
    line-height: 45px;
    border: 1px solid #bee5eb;
    border-radius: 0.25rem;
    color: #0c5460;
    background-color: #d1ecf1;
    padding-left: 20px;
    margin-bottom: 20px;
}