.return-order{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.return-order .heading{
    width: 70%;
    margin-top: 20px;
    padding: 10px 0;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #8080806e;
}

.return-order .order-info{
    width: calc(70% - 30px);
    padding: 10px;
    word-wrap: break-word;
    border: 1px solid #8080806e;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 700;
}

.return-order .order-info div{
    display: flex;
    justify-content: space-between;
}

.return-order .cta-btns{
    border-top: 1px solid #8080806e;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 70%;
}

.return-order .return-message{
    margin: 0 40px;
    padding-left: 10px;
    width: 70%;
    text-align: left;
    line-height: 45px;
    border: 1px solid #bee5eb;
    border-radius: 0.25rem;
    color: #0c5460;
    background-color: #d1ecf1;
    font-size: 0.875rem;
    font-weight: 400;
    word-wrap: break-word;
}

.return-order .back-btn{
    width: 70%;
    align-items: flex-start;
}

.return-order .return_reaons{
    width: 70%;
}