.store-order-line{
    width: 100%;
    border-collapse: collapse;
    font-size: 11px;
}


.store-order-line thead{
    position: sticky;
    /* top: 50px; */
    background: #0084C8;
}

.store-order-line tr{
    cursor: pointer;
}

.store-order-line th{
    font-weight: 700;
}

.store-order-line td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
.store-order-line tr:nth-child(even) {
    background-color: #dddddd;
}
