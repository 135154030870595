
.form {
    max-width: 330px;
    height: 70vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-top: 30px;
    border: 1px solid #8080806e;
}

.application-icon{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}


.form .form__custom-button{
    border: 1px solid #8080806e;
    background-color: #00A09D;
    margin-bottom: 25px;
}

.login-error{
    border: 1px solid #f5c6cb;
    background-color: #f8d7da;;
    padding: 10px;
    text-align: center;
    color: #721c24;
    margin-bottom: 15px;
}

.link{
    margin-top: 5px;
    font-size: 13px;
    color: #00A09D;
}

.link.register{
    justify-content: center;
}

.link.account{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.link span:hover{
    cursor: pointer;
    text-decoration: underline;
}
