.search-bar-section {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.company-types-dropdown {
    width: 150px;
    background-color: #0084C8;
    border: 0.5px solid #0084C8;
    font-weight: 400;
    padding-left: 5px;
    border-radius: 5px 0 0 5px;
}

.company-search {
    height: 30px;
    border: 0.5px solid#0084C8;
    border-radius: 0 5px 5px 0;
}

.search-icon {
    position: absolute;
    right: 5px;
}

.product-search {
    padding: 5px;
    border-radius: 2px;
    border: 0.5px solid #8080806e;
    width: 100%;
}

.products-search-list {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    float: left;
    min-width: 20rem;
    max-width: 300px;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    height: 400px;
    overflow: auto;
    z-index: 2;
}

.products-search-list .product {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 0 5px 5px;
}

.products-search-list .product img {
    width: 64px;
    height: 64px;
    object-fit: contain;
}

.products-search-list .product .name {
    font-size: 14px;
    font-weight: 700;
    word-wrap: break-word;
    flex-basis: 70%;
}

.products-search-list .product .price {
    display: flex;
    flex-direction: column;
}

.products-search-list .view-more-products {
    text-align: center;
    color: #00A09D;
    font-size: 13px;
    cursor: pointer;
}

/* Ankit Start 26-dec-2022 */

@media screen and (max-width: 785px) {
    .company-search {
        width: 100px;
        }
    .search-icon{
        display: none;
    }
    .company-types-dropdown {
        width: 100px !important;
    }
    .MuiSelect-select {
        /* width: 40px !important; */
    } 
    .search-icon {
        display: none !important;
    }
    .MuiSelect-icon {
        width: 20px !important;
        padding: 0 0 !important;
    }
    
}


@media screen and (max-width: 396px) {
    .company-search {
        width: 100px;
        }
    .search-icon{
        display: none;
    }
    .company-types-dropdown {
        width: 100px !important;
    }
    .MuiSelect-select {
        /* width: 40px !important; */
    } 
    .search-icon {
        display: none !important;
    }
    .MuiSelect-icon {
        width: 20px !important;
        padding: 0 0 !important;
    }
    
}


/* Ankit End 26-dec-2022 */
