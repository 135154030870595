.store-orders .order{
    border: 1px solid #8080806e;
    padding: 5px;
    /* width: 22%; */
    width: 100%;
    max-width: 250px;
    cursor: pointer;
}

.store-orders .order >div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.store-orders .order .highlight{
    font-weight: 700;
}

.store-orders .order .state{
    background: #4C4C4C;
    color: beige;
    padding: 2px 10px;
    border-radius: 10px;
    font-size: 10px;
}

.store-orders .order span{
    word-wrap: break-word;
}