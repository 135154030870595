.renew-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.renew-table-line{
    width: 70%;
    border-collapse: collapse;
    font-size: 14px;
    margin: 10px;
}

.renew-table-line thead{
    position: sticky;
    top: 50px;
    background: #0084C8;
}

.renew-table-line tr{
    cursor: pointer;
}

.renew-table-line th{
    font-weight: 700;
}

.renew-table-line td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 10px;
    width: fit-content;
  }
  
.renew-table-line tr:nth-child(even) {
    background-color: #dddddd;
}