table.rounded-corner {
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    outline: none;
    color: rgba(0, 0, 0, 0.6);
  }

tfoot.table-footer:first-child{
    text-align: center !important;
}