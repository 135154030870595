.locations__heading{
    border-bottom: 1px solid #8080806e;
    padding:10px 0;
    font-weight: bolder;
}

.location__main-content{
    border-bottom: 1px solid #8080806e;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input__box{
    display: flex;
    width: 80%;
    height: 35px;
    align-items: center;
}

.location__main-content input{
    width: 90%;
    border:none;
    min-height: 35px;
    border: 1px solid #8080806e;
    box-shadow: 2px 5px 4px #aaa9a9;
    color:#495057;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    margin-right: 15px;
    word-wrap: break-word;
}

.location__options{
    width: 80%;
    margin-top: 15px;
    min-height: 50px;
    max-height: 250px;
    overflow: auto;
    word-wrap: break-word;
}

.main_location{
    font-size: 16px;
    font-weight: bolder;
    color: #282c3f;
    cursor: pointer;
}

.main_location:hover{
    color: #FF1B1B;
}

.helper__location{
    font-size: 13px;
    color: #93959f;
}

.helper__location.borderBottom{
    border-bottom: 1px solid #8080806e;
    padding: 5px 0;
    margin-bottom: 5px;
}

.custom-dialog_actions{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 25px;
}

.custom-dialog_actions button{
    margin-right: 10px;
}

.available-coupons__1{
    color: #282c3f;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    background-color: #ece6e6;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.available-coupons__1 input{
    width: 20px;
    height: 20px;
}

.available-coupons__1 > div{
    display: flex;
    flex-direction: column;
    gap: 2px;
    word-wrap: break-word;
}

.available-coupons__1 .code{
    color: #48c479;
    border: 1px dashed #48c479;
    border-radius: 3px;
    padding: 5px;
    font-size: 14px;
    width: fit-content;
}

.payment-form .payment-form__inputs{
    padding: 5px;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.payment-form .payment-form__inputs{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.payment-form .payment-form__inputs label{
    font-weight: 600;
    padding-bottom: 5px;
    color:#212529;
    width: 30%;
    word-wrap: break-word;
}