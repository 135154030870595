.store-orders{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin:  10px;
    font-size: 13px;
}

.console__header{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 30px;
}

.console__header .dropdown{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.console__header .dropdown > *{
    font-size: 13px;
}