.apply-promo{
    display: flex;
    justify-content: center;
    height: 30px;
    margin: 10px 0 10px 10px;
    width: 100%;
    border: 1px solid #8080806e;
}

.apply-promo input{
    height: 100%;
    padding: 0;
    border: none;
}

.apply-promo button{
    position: relative;
    border: none;
    height: 100%;
    width: 100%;
    color: #FFFFFF;
    background-color: #875A7B;
    border-color: #875A7B;
    cursor: pointer;
}

.apply-promo button:disabled{
    background: #dddddd;
    border-color: #dddddd;
    color: rgb(139, 134, 134);
    cursor: not-allowed;
}

.available-coupons{
    position: relative;
    border: none;
    height: 100%;
    width: 100%;
    color: #FFFFFF;
    background-color: #875A7B;
    border-color: #875A7B;
    cursor: pointer;
    height: 30px;
    margin: 10px 0 10px 10px;
}