.payment-confirmation{
    padding: 0 70px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    color:#212529;
    font-size: 0.875rem;
    font-weight: 400;
}
@media screen and (max-width: 600px) {
    .payment-confirmation{     
        padding: 0 10px;                
    }
        
  }

.order-name{
    font-size: 15px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 15px;
}

.payment-confirmation .payment-info1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.payment-confirmation .payment-info1__heading{
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 7px;
    border-bottom: 1px solid #8080806e;
    margin-bottom: 7px;
}
