.order-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 70px;
    font-size: 10px;
}

@media screen and (max-width: 600px) {
    .order-form {
        /* padding-top: 50px;
        padding-right: 0;        
        padding-bottom: 50px;
        padding-left: 0; */
        padding: 0;
        
    }
    
    
  }

.order-form h4{
    font-size: 16px;
}



.order-form header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #8080806e;
    padding: 0 10px;
    margin-bottom: 5px;
}

.order-form header .header__left{
    display: flex;
    gap: 10px;
    align-items: center;
}

.order-form header .header__left button{
    max-height: 30px;
    font-size: 13px;
}

.order-form__heading{
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    margin-right: 30px;
}

.order-form form{
    width: 100%;
}

.order-form form fieldset > span{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.order-form__inputs{
    width: 30%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    font-size: 10px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.order-form__inputs.checkbox{
    flex-direction: row;
}

.order-form__inputs label{
    font-weight: 600;
    padding-bottom: 5px;
    color:#212529;
    width: 30%;
    word-wrap: break-word;
}

.order-form__inputs input{
    padding: 5px;
    border: 1px solid #727679;;
    border-radius: 0px;
    margin-bottom: 10px;
}

.order-form__inputs .prefix{
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #999;
    padding: 10px 5px;
}
/* Ankit Start 7 Dec 2022 */
.store-order-total{
    float: right;
    margin-bottom: 1rem;
}
/* Ankit End 7 Dec 2022 */

.dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 20%;
    top: 10%;
  }
  
  .image {
    width: 600px;
  }
