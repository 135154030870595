.addressess{
    padding: 0 70px;
    min-height: 400px;
}
@media screen and (max-width: 600px) {
    .addressess {

        padding: 0;
        
    }
    
    
  }


.billing-address__heading, .shipping-address__heading{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #8080806e;
    padding-bottom: 10px;    
}

.shipping-address__heading{
    margin-top: 25px;
}

.add-address{
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background-color: #875A7B;
    border-color: #875A7B;
    border-radius: 0px;
    font-size: 13px;
    color: beige;
    cursor: pointer;
}

.address-boxes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.address-box{
    position: relative;
    min-height: 130px;
    width: 45%;
    border: 1px solid #8080806e;
    font-size: 0.875rem;
    font-weight: 400;
    color:#212529;
    margin-bottom: 10px;
}

.address-box.highlight{
    border-color: #00A09D;
}

.address-box .edit-address{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #00A09D;
    cursor: pointer;
}

.address-box__footer{
    width: 100%;
    height: 50px;
    bottom: 0;
    border-top: 1px solid #8080806e;
    display: flex;
    align-items: center;
    justify-content: center;
}

.address-box__footer button{
    width: 85%;
    height: 60%;
    background-color: #875A7B;
    border-color: #875A7B;
    border-radius: 0px;
    font-size: 13px;
    color: beige;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.address-box__footer .selected{
    background-color: #00A09D;
    border-color: #00A09D;
}
