.signup-form{
    max-width: 330px;
    min-height: 70vh;
    margin: 0 auto;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-top: 30px;
    border: 1px solid #8080806e;
}

.signup-form__heading{
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #8080806e;
    padding-bottom: 10px;
    color:#212529;
}

.signup-form form{
    width: 100%;
}

.signup-form__inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    font-size: 13px;
}

.signup-form__inputs label{
    font-weight: 600;
    padding-bottom: 5px;
    color:#212529;
}

.signup-form__inputs > input{
    min-width: 300px;
    padding: 7px;
    border: 1px solid #ced4da;
}

.signup-form form .sign-up-btn{
    width: 100%;
    color: #FFFFFF;
    background-color: #00A09D;
    font-weight: 400;
    text-align: center;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
}

.signup-form form .login-route-link{
    color: #00A09D;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
}

.signup-form form .login-route-link:hover{
    text-decoration: underline;
}

.signup-form__inputs .prefix{
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #999;
    padding: 10px 5px;
}

.input-error{
    color: red;
    padding: 2px;
    font-size: 11px;
}