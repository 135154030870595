.loader {
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.company-info {
    min-height: 180px;
    width: 100%;
    background-color: #141F1E;
    margin-bottom: 5px;
    display: flex;
    gap: 20px;
    padding-left: 20px;
    color: #FFFFFF;
    padding-top: 20px;
    justify-content: flex-start;
}

.company-info>div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 500;
    gap: 10px;
}

.company-info .deliveryOptions,
.company-info .rating {
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: fit-content;
}

.company-info .rating {
    background-color: #009E53;
    padding: 2px 5px;
}

.company-info .companyName {
    font-size: 15px;
    font-weight: 500;
}

.company-info .companyImg {
    width: 250px;
    height: 150px;
}

.company-coupons {
    display: flex;
    flex-direction: column;
    gap: 3px;
    overflow: auto;
    border: dotted 3px #48c479;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 13px;
    max-height: 100px;
}

.company-coupons li {
    font-size: 13px;
    color: #FFFFFF;
    word-wrap: break-word;
}

.product-navigation {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-navigation>div {
    flex-basis: 33%;
}

.product-navigation .sort-by {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.product-navigation .sort-by>* {
    font-size: 13px;
}

.main-content {
    display: flex;
}

.main-content.column {
    flex-direction: column;
}

.product-categories {
    margin: 15px;
    margin-top: 25px;
    flex-basis: 30%;
}

.order-by-snapshot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.order-by-snapshot button {
    padding: 5px 15px;
    background-color: #68465F;
    color: beige;
    border: none;
    cursor: pointer;
}

.product-categories .content {
    display: flex;
    flex-direction: column;
    font-size: 13px !important;
    color: #00A09D;
    margin-left: 30%;
    border: 1px solid #8080806e;
    padding: 5px 20px;
    background-color: #F6F6F6;
    margin-right: 10%;
}

@media screen and (max-width: 600px) {
    .product-categories .content {
        margin-left: 1%;
        margin-right: 10%;
        
    }
    .product-categories {
        margin: 1px;
        
    }
    .company-info {
       width: auto;
    }
    
    
  }

.product-categories .content span {
    padding: 3px;
    cursor: pointer;
}

.product-categories .content .selected {
    background-color: #00A09D;
    color: beige;
}

.products {
    margin: 15px;
    flex-basis: 70%;
    display: flex;
    flex-wrap: wrap;
}

.products .fullwidth {
    flex-basis: 100%;
}

.product-card {
    max-width: 180px;
    margin: 10px;
    border: 1px solid #8080806e;
    color: #212529;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    max-height: 260px;
    height: auto;
}

.product-card:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.product-card__image {
    height: 180px;
    width: 180px;
}

.product-card__details {
    position: relative;
    border-top: 1px solid #8080806e;
    text-align: center;
}

.product-card__name {
    display: block;
    font-weight: 400;
    color: #00A09D;
    text-align: center;
    padding-top: 2px;
    word-wrap: break-word;
}

.product-card__price {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
}

.strike {
    text-decoration: line-through;
    color: #dc3545;
}

.product-card .compare-icon {
    display: none;
    position: absolute;
    bottom: 50%;
    right: 30%;
    color: beige;
}

.product-card .compare-icon * {
    background-color: #68465F;
    padding: 5px;
    z-index: 999;
}

.product-card:hover .compare-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.addBorderColor{
    /* border: 2px solid red; */
    /* background-color:#00A09D; */
    text-align: center;
    align-items: center;
}
.addBackgroundColor{
    
    /* border: 2px solid red; */
    margin-left: 20px;
    margin-right: 20px;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
