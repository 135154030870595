.console-form__inputs .dailoge_input {
    width: auto;
}

span.dialogContent_style {
    display: flex;
}

span.dialogContent_style .right_box {
    flex-grow: 2;
}

span.dialogContent_style .left_box {
    flex-grow: 1;
}

div.img_container {
    padding: 5px;
    border-radius: 0px;
    margin-bottom: 10px;
}

div.img-cards{
    display: flex;
}

div.img-card-view{
    margin: 1rem ;
}
.uploaded-image .img-preview{
    width: 200px;
    height: 150px;
    object-fit: contain;
    padding: 10px 0;
    margin: 10px;
    border: 1px solid #8080806e;
}

.uploaded-video .video-view{
    width: 200px;
    height: 150px;
    object-fit: contain;
    padding: 10px 0;
    margin: 10px;
    border: 1px solid #8080806e;
}