.product-data {
    display: flex;
    width: 100%;
}

.left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-top: 2%;
}

.left img {
    max-width: 400px;
    max-height: 500px;
}

.right {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.right .variants-radio-view {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 13px;
}

.right .variants-radio-view div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.product-image {
    height: 300px;
    width: 300px;
}

.product-name {
    font-weight: bold;
    margin: 15px 0;
}

.product-price {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.product-price p {
    font-weight: 600;
    margin-bottom: 5px;
}

.product-price .strike-through {
    text-decoration: line-through;
    color: #dc3545;
}

#add-to-cart {
    color: #FFFFFF;
    background-color: #00A09D;
    border-color: #00A09D;
    font-size: 13px;
    margin: 10px;
    width: 150px;
}

.combination-noexists {
    padding: 15px;
    margin: 5px;
    color: #856404;
    background: #fff3cd;
    font-size: 15px;
    border-radius: 0.25rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.quantity {
    display: flex;
    height: 30px;
    margin: 0 0 10px 10px;
}

.quantity input {
    width: 60px;
    border: none;
    text-align: center;
    height: 26px;
    border: 1px solid #8080806e;
}

.quantity button {
    width: 30px;
    border: none;
    color: #FFFFFF;
    background-color: #875A7B;
    cursor: pointer;
}

.recently-viewed-products {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 10px 10px;
}

.recently-viewed-products .heading {
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0;
}