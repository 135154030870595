
.selection-field-css{
    display: flex;
    flex-direction:row ;
    /* flex-wrap: nowrap !important; */
    margin-left: 10px;
}
.date-time-div{
    display: inline-block;
    margin: 10px;
}
.date-time-div label{
    margin-right: 10px;
}
.rule-btn-div{
    margin-left: 7px;
}
@media screen and (max-width: 847px) {
    body{
        /* margin: 5%; */
    }
    .console-form form fieldset > span{
        flex-direction: column;
        gap: 2px;
        margin-right: 5px;
        margin-left: 5px;
    }
    .rule-btn{
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
    .console-form__inputs{
        width: auto;
    }
}

@media screen and (max-width: 600px) {
    
}



@media screen and (max-width: 370px) {

}
