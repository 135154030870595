.my-coupons{
    font-size: 13px;
    width: 80%;
    margin-left: 10%;    
    margin-top: 15px;
}

.my-coupons__header{
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #8080806e;
    padding: 3px;
    color: #6c757d;
}

.my-coupons__cards{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.coupon__card{
    border: 1px solid #27D800;
    width:30%;
    padding: 10px;
    margin: 5px;
    border-radius: 0.25rem;
}

.coupon__card div{
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cta-btn{
    padding: 10px 0;
    margin-bottom: 0 !important;
    border-top: 1px solid #8080806e;
}

.cta-btn button{
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: #C82333;
    color: beige;

}

.coupon__card .comp-type{
    font-size: 11px;
    color: #999999;
}

.coupon__card .highlight{
    font-weight: bolder;
}

.coupon__card .comp-name,.coupon__card .reference{
    color: #00A09D;
    font-weight: bolder;
    cursor: pointer;
}

.coupon__card .comp-name:hover,.coupon__card .reference:hover{
    text-decoration: underline;
}

.coupon__card .state{
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    border: 1px solid #0000ff;
}

.no-coupons{
    margin: 20px 40px;
    padding-left: 10px;
    width: 55%;
    text-align: left;
    line-height: 45px;
    border: 1px solid #bee5eb;
    border-radius: 0.25rem;
    color: #0c5460;
    background-color: #d1ecf1;
    font-size: 0.875rem;
    font-weight: 400;
}