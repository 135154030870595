.company-registration{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 70px;
}

.company-registration__heading{
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #8080806e;
    padding-bottom: 10px;
    color: #212529;
}

.company-registration form{
    width: 100%;
}

.company-registration form > span{
    display: flex;
    gap: 20px;
}

.company-registration__inputs{
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    font-size: 13px;
}

.company-registration__inputs label{
    font-weight: 600;
    padding-bottom: 5px;
    color:#212529;
}

.company-registration__inputs input{
    min-width: 300px;
    padding: 7px;
    border: 1px solid #ced4da;
}

.company-registration__inputs .prefix{
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #999;
    padding: 10px 5px;
}

.company-registerd{
    margin: 20px 40px;
    width: 55%;
    font-size: 0.875rem;
    font-weight: 400;
}

.company-registerd div{
    text-align: left;
    line-height: 45px;
    border: 1px solid #bee5eb;
    border-radius: 0.25rem;
    color: #0c5460;
    background-color: #d1ecf1;
    padding-left: 20px;
    margin-bottom: 20px;
}